@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&subset=cyrillic');

$primary-color: #AA1E46;
$secondary-color: lighten($primary-color, 47.5%);
$base-font-family: 'Roboto Condensed';

@import '~spectre.css/src/spectre';
@import '~spectre.css/src/spectre-icons';
@import '~spectre.css/src/spectre-exp';

@import '~react-day-picker/lib/style.css';

@include margin-variant(4, $unit-4);
@include margin-variant(6, $unit-6);


.table {
  &.table-narrow {
    td, th {
      padding: $unit-h $unit-2;
    }
  }
}

.cols, .columns {
  &.col-narrow {
    margin-left: -$unit-1;
    margin-right: -$unit-1;

    & > .column {
      padding-left: $unit-1;
      padding-right: $unit-1;
    }
  }
}

.bg-warning-l { background-color: lighten($warning-color, 30%) !important; }
.bg-success-l { background-color: lighten($success-color, 30%) !important; }

.font-light {
  font-weight: 300;
}

.select-text-uppercase {
  text-transform: uppercase;
  option {
    text-transform: none;
  }
}

.timeline .timeline-item {
  &:last-child { margin-bottom: 0; }
  &.timeline-item-final:before { content: none; }
}

// ms edge (and probably all chromium browsers) set this to content-box
// and it distorts .timeline .timeline-item .timeline-icon::before
// there was no problem on checkbox-version of .accordion and appeared after moving to details-version
.accordion-body {
  box-sizing: border-box;
}

textarea[name="comment"] {
  min-height: 6rem;
  resize: vertical;
}



#root {
  max-width: 1150px;
  margin: 0 auto;
  padding: 1em;
}

#sign-in-form {
  max-width: 20rem;
  margin: 3em auto;
  img { width: 100%; }
}

#navbar {
  margin-bottom: $unit-6;
  .btn-link.active {
    background: $primary-color;
    color: white;
    box-shadow: none;
  }
  .btn-group {
    flex-wrap: nowrap;
  }
}


.tabs {
  width: 100%;
  max-width: 20rem;
  display: inline-flex;
  margin: 0 $unit-2 $unit-4;
}

.requests-list {
  .accordion:not(:first-of-type) {
    margin-top: $unit-6;
  }

  .accordion-header {
    padding: 0;
    &:hover {
      background-color: $secondary-color;
    }
   }

  .request {
    margin-bottom: $layout-spacing;
    &.opened .tile-action .icon { transform: rotate(-180deg); }
  }

  .tile {
    padding: $unit-1 $unit-2;
    &:hover { background: $secondary-color; }
  }

  .tile-icon { // see .timeline-icon
    height: $unit-6;
    width: $unit-6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    color: $light-color;

    &.icon-lg {
      background: $primary-color;
      line-height: $line-height;
    }
  }

  .tile-action .icon { transition: transform .25s; }

  .timeline {
    margin-left: $unit-10;
    overflow: hidden;
    //animation: .1s ease 1 slide-down;
    margin-bottom: $layout-spacing;
  }
}


#timeline-legend div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: top;
}

#timeline-wrapper {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  padding: 0 5vw;
}

#users-modal {
  .modal-container {
    min-height: 70vh;
    max-width: 720px;
  }

  .modal-body {
    flex: 1; // force .column's to stretch vertically
    display: flex;
    overflow-y: hidden;
  }

  .modal-footer { margin-top: auto; }

  .columns {
    overflow-y: scroll;
  }
  .column {
    .btn-clear {
      display: none;
      position: relative;
      top: $unit-h;
    }
    &:first-child a:hover .btn-clear {
      display: inline-block;
    }
  }

  .menu-item { cursor: pointer; }
}


#request-form {
  .DayPickerInput {
    display: block;
    .form-input[readonly] {
      background-color: inherit;
    }
  }
  .DayPicker {
    font-size: inherit;
    line-height: 1.1;
  }
}


@media (max-width: $size-sm) {
  .tabs {
    margin-left: 0;
    margin-right: 0;
  }

  .requests-list .empty {
    padding: 2em 1em;
    position: relative;
    left: -1em;
    width: 100vw;
  }

  .absence-requests-list {
    > .column:nth-child(1) { order: 2; }
    > .column:nth-child(2) { margin-bottom: $unit-4; }
  }

  // copied directly from .table-scroll (scss prohibits direct @extends inside media queries)
  .table {
    display: block;
    overflow-x: auto;
    padding-bottom: .75rem;
    white-space: nowrap;
  }
}


@media (max-width: $size-md) {
  .text-left-md { text-align: left; }

  #navbar {
    overflow-x: scroll;
    margin-bottom: $unit-4;
  }

  .requests-list {
    .timeline { margin-left: 1.2rem; }

    //.empty {
    //  padding: 2em 1em;
    //  position: relative;
    //  left: -1em;
    //  width: 100vw;
    //}

    .tile { padding: 0.2rem 0; }
  }

  #request-form button {
    width: 100%;
  }
}


@media (max-width: $size-lg) {
  .text-left-lg { text-align: left; }
}


// event form
@media (min-width: $size-md) {
  fieldset {
    border: 1px solid grey;
    padding: 0.5rem 1rem 1rem;
  }

    legend {
    padding: 0 0.5rem;
    margin-bottom: 0;
  }
}