.timeLine {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(207, 207, 205);
  font-size: 12px;
  user-select: none;
}

/* Header */

.timeLine-control {
  background-color: #fbf9f9;
  .btn.btn-link { color: chocolate; }
  flex: 0 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.timeLine-header-viewPort {
  flex: 1 1 100%;
  position: relative;
  background-color: rgb(112, 112, 112);
  overflow: hidden;

  .timeLine-header-container {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
  }
  
  .timeLine-header-row {
    border-bottom: 1px solid silver;
    color: white;
    font-size: 10px;
    height: 20px;
  
    &.header-top { background-color: #333333; }
    &.header-middle { background-color: chocolate; }
    &.header-bottom { background: grey; }
  }
  
  .timeLine-header-item {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid white;
    height: 20px;
  }
}

/* Table Area */

.timeLine-section-content {
  position: relative;
  display: flex;
  overflow-y: hidden;
}

.timeLine-section-title {
  background-color: #333333;
  border-bottom: 1px solid silver;
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
}

.timeLine-side {
  flex: 0 0 auto;
  width: 108px;
  min-width: 108px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(207, 207, 205);
  
  .timeLine-side-rows-container {
    position: relative;
    flex: 1 1 auto;
    height: 100%;
    background-color: #fbf9f9;
    overflow: hidden;
  }
  
  .timeLine-side-row {
    position: absolute;
    width: 100%;
    border: 1px solid rgb(207, 207, 205);
    color: grey;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

.timeLine-resizer {
  flex: 0 0 8px;
  cursor: col-resize;
  border-right: 1px solid rgb(207, 207, 205);
  background-color: #333333;
}


/* Main Area Data*/

.timeLine-data-viewPort {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  background-color: #fbf9f9;

  .timeLine-data-container {
    position: absolute;
  }
  
  .timeLine-data-row {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #cfcfcd;
  }

  .timeLine-grid {
    position: absolute;
    height: 100%;
    width: 1px;
    // transform: scaleX(0.5);
    background-color: silver;
  }

  .timeLine-cols {
    position: absolute;
    display: flex;
    height: 100%;
  }

  .timeLine-col {
    border-left: 1px solid silver;
  }
  
  .timeLine-data-task {
    position: absolute;
    background-color: darkorchid;
    height: 100%;
    background-clip: content-box;
    color: white;
    text-align: center;
    overflow: hidden;
  
    &.selected {
      box-shadow: inset 0px 0px 8px black;
    }
  }
}